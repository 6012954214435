import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoWhite } from '../assets/galderma-white.svg';
import { ReactComponent as FacebookIcon } from '../assets/facebook.svg';
import { ReactComponent as InstagramIcon } from '../assets/instagram.svg';
import { ReactComponent as YoutubeIcon } from '../assets/youtube.svg';
import './Footer.scss';
import Config from '../Config';
import DownloadLink from '../controls/DownloadLink';

function Footer() {
    // useEffect(() => {
    //     window.OneTrust.initializeCookiePolicyHtml();
    // }, []);

    console.log("view");
    return (
        <Container fluid className="footer-container">
            <Row className="logo">
                <Col>
                    <LogoWhite />
                </Col>
            </Row>
            <Row className="content">
                <Col className="content-menu">
                    <Link to="/">Home</Link>
                    <Link to="/profile">Twoje konto</Link>
                    <Link to="/contact">Kontakt</Link>
                    {/* <button id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia cookies</button> */}
                    {/* <a href="#" onClick={() => { window.OneTrust.ToggleInfoDisplay(); }}>Ustawienia cookies</a> */}
                    <Link to="/cookie-policy">Polityka cookies</Link>
                    <DownloadLink to="/polityka-prywatnosci-i-cookies.pdf">Polityka prywatności</DownloadLink>
                    <DownloadLink to="/regulamin-platformy-galderma4u.pdf">Regulamin</DownloadLink>
                </Col>
            </Row>
            <Row className="social">
                <Col>
                    <div className="social-icon">
                        <a href="https://www.facebook.com/GaldermaAestheticsPolska"><FacebookIcon /></a>
                    </div>
                    <div className="social-icon">
                        <a href="https://www.instagram.com/galderma_aesthetics_polska"><InstagramIcon /></a>
                    </div>
                    <div className="social-icon">
                        <a href="https://www.youtube.com/channel/UCaVVREwW0cCWMEZGNlHoGvg"><YoutubeIcon /></a>
                    </div>
                </Col>
            </Row>
            <Row className="disclaimer">
                <Col>
                    <p>© 2025 Galderma Polska Sp. z o.o. Wszystkie prawa zastrzeżone. Wszystkie znaki handlowe są własnością odpowiednich podmiotów. Ta strona jest przeznaczona wyłącznie dla odbiorców w Polsce.</p>
                </Col>
            </Row>
        </Container >
    )
}

export default Footer;
